.ra-input-picture>div>div {
    flex-direction: column-reverse;
    align-items: center;
    min-height: 8rem !important;
}

.ra-input-picture>div>div>p {
    display: none;
}

form.userTabs div[data-testid="dropzone"] {
    background-color: lightgray;
    width: 19rem !important;
    height: 1.5rem;
}

form.userTabs div[data-testid="dropzone"]>p {
    margin: 0 auto !important;
    padding: 0 auto !important;
}

.ra-input-picture>div>label>span {
    color: black;
    font-size: 1.5rem;
}

.ra-input-picture>div>.previews {
    width: 33.5% important;
}

.AerialTable .MuiTableCell-head {
    background-color: #003488 !important;
    color: white !important;
}