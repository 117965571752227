
.footer {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 9%;
    background-color: #fafafa;
    color: black;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1300;
    box-sizing: border-box;
}


.collaborators-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    max-height: 100%;

}

.collaborators-block, .financed-block {
    flex: 1;
    margin: 0 15px;
}

.logos {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

.logos img {
    max-height: 50px;
    object-fit: contain;
}


.more-information-block{
    align-content: end;
}


.collaborators-more-information-block {
    flex: 1;
    margin: 20px;
    font-weight: bold;
}

.link-style {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
}
.link-style:hover {
    color: darkblue;
}

.header-logo{
    font-size: 14px !important; 
    font-family: 'Quicksand' !important;
}


.header-dialog-logo{
    font-size: 18px !important;
    font-family: 'Quicksand' !important;
    font-weight: 700;
}

.hiddenFooter {
    visibility: hidden;
}
.visibleFooter {
    visibility: visible;
    transition: visibility 0s 2s; /* Esto retrasa la visibilidad 3 segundos */
}
