.ChangePassword-form {
    height: 100vh !important;
    width: 100vw !important;
    justify-content: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.navbar {
    background-color: #003488;
    box-shadow: 2px 4px 0 rgba(0, 0, 0, 0.123);
    color: transparent;
    height: 50px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.password-input {
    width: 348px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F0F0F0;
}

.ChangePassword-form>div.ChangePassword-form-style>img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 238px;
    height: 80px;
    top: 100px;
    padding-left: 44px;
}

.ChangePassword-form>div.ChangePassword-form-style>h1.title {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.ChangePassword-form>div.ChangePassword-form-style>p.name {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    font-size: 21px;
}

.ChangePassword-form>div.ChangePassword-form-style>p {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 19px;
    line-height: 10px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ChangePassword-form>div.passwordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 10px;
}

.ChangePassword-form>div.passwordForm>div {
    width: 320px;
    height: 58px;
}

.ChangePassword-form>div.passwordForm>div>div>div {
    box-sizing: border-box;
    background-color: #F0F0F0;
    padding-top: 18px;
    border-radius: 2px;
}


.ChangePassword-form>div.passwordForm>div>div>div>input.MuiInputBase-input.MuiInput-input {
    font-size: 18px;
    margin-left: 14px;
    margin-bottom: 6px;
    color: #374bf7;
}



.ChangePassword-form>div>.submitButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #29327B;
    border-radius: 30px;
}

.ChangePassword-form>div>.submitButton>button.MuiButton-text {
    font-size: 17px;
    margin-top: 8px;
    color: white;
    letter-spacing: 0.25px;
}