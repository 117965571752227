.login-form {
    height: 100vh !important;
    width: 100vw !important;
    background-image: url('../assets/image/login-background.png');
    justify-content: center !important;
    background-repeat: no-repeat;
    display: flex;
    background-size: cover;
    flex-direction: column;
    align-items: center;
}

.login-form>div>img.veraImg {
    padding-bottom: 10px;
}

.login-form>div.login-form-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px !important;
    background-color: #61c9bf7e;
    padding-top: 42px;
    padding-left: 40px;
    padding-right: 40px;
    gap: 25px;
    width: 444px;
    height: 490px;
}

.login-form>div>div.input-form {
    width: 348px;
    display: flex;
    flex-direction: row;
    background-color: rgba(232, 240, 254, 1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.login-form>div>div.input-form>div {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: rgba(232, 240, 254, 1);
}

.login-form>div>div>div>label {
    font-size: 16px;
    position: relative;
    margin-bottom: -18px;
    right: 3px;
    color: rgba(0, 0, 0, 0.54);
}

.login-form>div>button.button {
    width: 348px;
    height: 52px;
    border-radius: 52px;
    margin-top: 2px;
    background-color: #29327B;
}

.login-form>div>button.button>span {
    font-size: 16px;
}