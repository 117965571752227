@font-face {
  font-family: "FS Matthew";
  src: url('./fonts/FSMatthew-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "FS Matthew";
  src: url('./fonts/FSMatthew-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "FS Matthew";
  src: url('./fonts/FSMatthew.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "quicksand";
  src: url('./fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "FS Matthew", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding-bottom: 5%;
  box-sizing: border-box;
  background-color: #fafafa;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
input,
tr,
td,
th {
  font-family: "FS Matthew", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
}

/* .MuiDrawer-paperAnchorLeft div .MuiListItem-button:hover,
.MuiTabs-flexContainer .form-tab:hover,
.MuiButton-text:hover,
.MuiList-root .MuiListItem-root:hover {
  color: #003488 !important;
} */

.MuiButton-text[aria-label="Perfil"]:hover {
  color: #00ab8e !important;
}

/* button[type="submit"]:hover,
.MuiButton-contained:hover,
.MuiFab-root:hover {
  background-color: #003488;
  color: white;
} */

code {
  font-family: "FS Matthew", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}