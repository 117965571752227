.ql-container.ql-snow {
    height: auto !important;
}

.generalCommentQuill .ql-editor {
    height: 6rem !important;
    min-height: 6rem !important;
    max-height: 6rem !important;
    overflow-y: auto !important;
}

.commentQuill .ql-editor {
    height: 10rem !important;
    min-height: 10rem !important;
    max-height: 10rem !important;
    overflow-y: auto !important;
}

.disabledActivityButton {
    background-color: lightgrey !important;
    color: black !important;
}